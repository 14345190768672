<template>
    <b-card title="Create Inspired">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Name -->
                    <b-col cols="12">
                        <b-form-group label="Title" label-for="title">
                            <validation-provider name="title" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="title" v-model="title"
                                        :state="errors.length > 0 ? false : null" placeholder="Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!--Short Description -->
                    <b-col cols="12">
                        <b-form-group label="Short Description" label-for="Short description">
                            <validation-provider name="short_description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                        <b-form-textarea id="short_description" v-model="short_description" 
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Button Text -->
                    <b-col cols="12">
                        <b-form-group label="Button Text" label-for="Button Text">
                            <validation-provider name="btext" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="btext" v-model="btext"
                                        :state="errors.length > 0 ? false : null" placeholder="Button Text" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewImg" :class="previewImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profile" v-model="profileImg" :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Title -->
                    <b-col cols="12">
                        <b-form-group label="Meta Title" label-for="meta title">
                            <validation-provider name="meta_title" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_title" v-model="meta_title"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <!-- Meta Description -->
                    <b-col cols="12">
                        <b-form-group label="Meta Description" label-for="meta description">
                            <validation-provider name="meta_description" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>

                                    <b-form-textarea id="meta_description" v-model="meta_description" placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <!-- Meta Keyword -->
                    <b-col cols="12">
                        <b-form-group label="Meta Keyword" label-for="meta Keyword">
                            <validation-provider name="meta_keyword" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_keyword" v-model="meta_keyword"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Keyword" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required
} from '@validations'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            title: '',
            checkedNames:'',
            short_description: '',
            previewImg: '',
            price: '',
            blogshowoption:'',
            discount: '',
            vat: '',
            btext: '',
            tag:'',
            finds: [],
            meta_title: '',
            meta_description: '',
            meta_keyword: '',
            profileImg: '',
            selectedoptions: null,
            selectedCountry: null,
            options: [
                {'value': null, text: 'Please select Publish / Unpblish'},
                {'value': 0, 'text': 'Unpublished'},
                {'value': 1, 'text': 'published'}
            ],
            countryOptions: [
                { 'value': null, text: 'Please select a country' },
            ]
        }
    },
    created() {
        this.getCountries();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        addFind: function () {
            this.finds.push({ value: '' });
          },
          deleteFind: function (index) {
            console.log(index);
            console.log(this.finds);
            this.finds.splice(index, 1);
          },
          add(){
            console.log('hghghf');
        
        event.preventDefault();
        // var field_forms = '<fieldset>' +  
        //                     '<div class="form-group">' +
        //                     '<label class="control-label" for="textinput">Name</label>' +
        //                       '<div class="col-md-12 margin-bottom collapse-col">' +
        //                       '<input name="tag[]" type="text" placeholder="Enter name" class="form-control input-md"></input>' +
        //                             '<button class="delete btn button-white uppercase fas fa-times"></button>' +
        //                       '</div>' +
        //                '</div>' +
        //            '</fieldset>';
                   
                   
         $(field_forms).insertBefore( $( "#add" ) );
          },
        reset() {
            this.title = '',
            this.short_description = '',
            this.previewImg = '',
            this.price= '',
			this.checkedNames= '',
            this.blogshowoption='',
            this.discount= '',
            this.vat= '',
            this.btext= '',
            this.tag='',
            this.meta_title = '',
            this.meta_description = '',
            this.meta_keyword = '',
            this.profileImg = '',
            this.selectedoptions= null,
            this.selectedCountry = null;
        },
        getCountries() {
            this.countries = [];
            db.collection("countries")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                            slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },
        onImageChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Profile Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();
                    fileReader.onload = (event) => {
                        this.previewImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewImg = '';
                }
            });
        },
        submit() {
            store.dispatch('app/commitActivateLoader');
            if(this.checkedNames == true){
            this.blogshowoption = 1;
			}else if(this.checkedNames == false){
			this.blogshowoption = 0;
			} 
            alert(this.blogshowoption)
             var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                            db.collection("inspired")
                            .add({
                                    title: this.title,
                                    btext: this.btext,
                                    short_description: this.short_description,
                                    meta_title: this.meta_title,
                                    meta_description: this.meta_description,
                                    meta_keywords: this.meta_keyword,
                                    created: date,
                                    modified: date,
                                })
                                .then((doc) => {
                                    console.log("Added Successfully!");
                                    const filename = this.profileImg.name;
                                    const ext = filename.slice(filename.lastIndexOf('.'));
                                    const storageRef = storage.ref();
                                    const fileRef = storageRef.child('inspired/' + doc.id + "." + ext);
                                    fileRef.put(this.profileImg).then(result => {
                                        fileRef.getDownloadURL().then(imageUrl => {
                                            console.log(imageUrl);
                                            db.collection("inspired").doc(doc.id).update({ image: imageUrl })
                                            store.dispatch('app/commitDeactivateLoader');
                                            this.$router.push({ name: 'inspired-index' });
                                            this.showNotification('Category Added Successfully!', 'AlignJustifyIcon', 'success');
                                        }).catch((error) => {
                                            console.error("Error writing document: ", error);
                                            this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                                        });
                                    });
                                })
                            // var id = localStorage.getItem("userSessionId");
                            // console.log('rrr ' + id);
        },
        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>